<template>
<v-container mt-flex fluid class="pa-0">
  <v-parallax
    dark
    max-height="500px"
    src="/imgs/gradientBG1.jpg"
    >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        class="text-center"
        md="4"
        
      >
        <h1 class="text-h2 font-weight-black mb-4 ts-2 text-uppercase ice--text">
          <strong>Contact <span class="customGreen--text">Us</span></strong>
        </h1>
        
      </v-col>
      <v-col
        class="text-center"
        md="4"
        
      >
        <v-img
          class=""
          src="/imgs/GRP1.png"
          cover>

        </v-img>
        
      </v-col>
    </v-row>
  </v-parallax>
  <v-row class="mt-5 pt-5" src="/imgs/gradientBG1.jpg">
    <v-col class="text-center">
      <h1 class="text-h3 font-weight-black mb-4 text-uppercase drab--text"><span class="fs-10">Our Staff</span></h1>
    </v-col>
  </v-row>
  <v-row>
    <v-col md="3" offset-md="3" justify="center" align="middle" class="pa-10 circle">
      <v-container tile align="middle">
        <v-img
          class="rounded-circle elevation-12"
          src="/imgs/26.jpg"
          elevation="12"
          max-height="250"
          max-width="250"
          cover></v-img>
          <h1 class="pt-5 drab--text">Ingrid Nel</h1>
          <h3 class="drab--text">071 354 9749</h3>
          <h4 class="drab--text">pretoriaeast@dancemouse.co.za</h4>
      </v-container>
    </v-col>
    <v-col md="3" justify="center" align="middle" class="pa-10 circle">
      <v-container tile align="middle">
        <v-img
          class="rounded-circle elevation-12"
          src="/imgs/27.jpg"
          elevation="12"
          max-height="250"
          max-width="250"
          cover></v-img>
          <h1 class="pt-5 drab--text">Marique de Klerk</h1>
          <h3 class="drab--text">072 664 8826</h3>
          <h4 class="drab--text">pretoriaeast@dmtots.co.za</h4>
      </v-container>
    </v-col>
  </v-row>
  <v-parallax
    dark
    src="/imgs/gradientBG2.jpg"
    max-height="250"
    >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        class="text-center"
        cols="6"
        
      >
        <h1 class="text-h2 font-weight-black mb-4 ts-2 text-uppercase">
          <strong class="slate--text">Contact <span class="cyan--text">Form</span></strong>
        </h1>        
      </v-col>
    </v-row>
  </v-parallax>
  <v-row class="pt-10 pb-15">
    <v-col md="6" offset-md="3" class="pl-10">
      <v-card outlined elevation="12" class="mx-auto pl-5 pt-5 pr-5 pb-5">
      <v-form
        id="contactForm"
        ref="form"
        @submit.prevent="sendEmail"
      >
        <v-text-field solo class="ma-2" label="Your Name" name="user_name"></v-text-field>
        <v-text-field solo class=" ma-2" label="E-mail" name="user_email"></v-text-field>
        <v-textarea solo label="Your Message" name="message"></v-textarea>
              <!-- <div class="g-recaptcha pb-5" data-sitekey="6LcN_V8eAAAAALV8qcZcW1M2eeBS8mjmEklYpiUC"></div> -->
              <v-btn class="white--text" :color="appTexts.parties.titleColor" @click="sendEmail">
                <v-icon>Submit</v-icon>
              </v-btn>
      </v-form>
      </v-card>

    </v-col>
    
  
  </v-row>
  </v-container>
</template>

<script>
import emailjs from '@emailjs/browser';

export default {
  methods: {
    sendEmail() {
      emailjs.sendForm('service_s63q1op', 'template_wb5x2c3', '#contactForm', 'user_ekWakOwXMUjZIj6WLwgYl')
        .then((result) => {
            console.log('SUCCESS!', result.text);
            alert("Email sent successfully!");
            this.$refs.form.reset();
        }, (error) => {
            console.log('FAILED...', error.text);
            alert("Email failed please sedn an email to pretoriaeast@dancemouse.co.za!");
        });
    },
  },
}
</script>
<style>

.view-enter-active {
  animation-duration: 1.5s;
  animation-name: slideIn;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-delay: 1s ease-in;
}

@keyframes slideIn {
  from {
    opacity: 0;
    margin-left:-100%;
  }
  
  to {
    opacity: 1;
    margin-left:0%;
  }
}

.bg-grey {
  background-color: rgba(0, 0, 0, 0.05);
}

.v-card.on-hover.theme--dark {
  transition: background-color 0.5s ease;
  background-color: rgba(#FFF, 0.8)
}
.bg-gradientClothing {
      background-image: linear-gradient(
  135deg
  ,#f342e4 25%,#ffb7ff 67%)!important;
}
</style>